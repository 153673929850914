<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
    ></page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/role.modal.js'
import { ref, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import _ from 'lodash' // 导入loadsh插件
import { useRouter } from 'vue-router'
// import { getGlobalParamsOptionsAsync } from '@/utils/common'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const router = useRouter()

pageTitle.value = t('general.router-role-new')

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  // const groupList = await getGlobalParamsOptionsAsync('groupList', {
  //   isNeedPreFix: false
  // })
  // const groupsArray = []

  // form.groups.map((item) => {
  //   for (const group in groupList) {
  //     if (groupList[group].value === item) {
  //       // groupsArray.push({ id: item, name: groupList[group].label })
  //       groupsArray.push(item)
  //     }
  //   }
  // })

  form.groups = form.groups && form.groups.length ? form.groups.join(',') : ''
  handleMessageCommit('user/handleAddRoleAction', form).then(() => {
    router.push('/user/role')
  })
}
</script>
