import {
  getGlobalParamsOptionsAsync,
  getCommonParamsOptions
} from '@/utils/common'

export const baseFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.role-name',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'groups',
      type: 'select',
      label: 'user.resource-group',
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('groupList', {
          isNeedPreFix: false
        }),
      otherOptions: {
        multiple: true
      }
    },
    {
      field: 'type',
      type: 'select',
      label: 'user.role-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('role_type')
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'user.role-description',
      otherOptions: {
        maxlength: 166
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    type: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    groups: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
